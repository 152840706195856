import React, { useEffect, useState } from "react";
import Event from "./components/Event";
import { Helmet } from "react-helmet";
import Layout from "./Layout";
import { APIURL } from ".";
import axios from "axios";
import Loading from "./components/UI/Loading";
import { Check, Sliders, X } from "@phosphor-icons/react";
import Dropdown from "./components/Form/Dropdown";

const Terminy = () => {
    const [active, setActive] = useState(0);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [places, setPlaces] = useState();
    const [type, setType] = useState('');

    let isMobile = window.innerWidth<1000;

    const getCurrentDate = () => {
        var now = new Date();
        var currentMonth = now.getMonth() + 1;
        if (currentMonth < 10) {
            currentMonth = "0" + currentMonth;
        }
        return now.getFullYear() + "-" + currentMonth + "-" + now.getDate();
    };

    const defaultData = {
        type: 'events',
        orderAsc: "ASC",
        limit: 50,
        page: 1,
        filter: [{ name: "date", action: ">", value: getCurrentDate() }],
    };

    const [filter, setFilter] = useState();

    const [eventsData, setEventsData] = useState({});

    const getEvents = async () => {
        setLoading(true);
        let data = { ...defaultData };
        if (filter) {
            data.filter = [...data.filter, filter];
        }
        if (type!=='') {
            data.filter = [...data.filter, {name:type, value:'true'}];
        }
        await axios.post(APIURL + "/events", data).then((response) => {
            setEventsData(response.data.body);
            if (response.data.body.header.find((p) => p.name == "location")?.options?.length>5) {isMobile=true}
            if (isMobile) {
                setPlaces(
                    [{value:0, label:'Vše'}, ...response.data.body.header.find((p) => p.name == "location")?.options?.map((p,i)=>({value:i+1, label:p}))]
                )
                console.log(places)
            } else {
                setPlaces(
                    response.data.body.header.find((p) => p.name == "location")
                );
            }
            setLoading(false);
        }).catch(e=>{
            console.log(e)
        });
    };

    const filterEvents = async () => {
        getEvents();
        setModal(false)
    }

    useEffect(() => {
        if (!modal) {
            getEvents();
        }
    }, [filter, type]);
    return (
        <Layout nested={1} className="terminy">
            <Helmet>
                <title>Termíny kurzů | Kurzy pro řidiče s hendikepem</title>
                <meta
                    name="description"
                    content="Zde naleznete aktuálně vypsané termíny kurzů, jejich místa konání a možnost registrace."
                />
                <meta property="og:title" content="Termíny kurzů" />
                <meta
                    property="og:description"
                    content="Zde naleznete aktuálně vypsané termíny kurzů, jejich místa konání a možnost registrace."
                />
                <meta name="twitter:title" content="Termíny kurzů" />
                <meta
                    name="twitter:description"
                    content="Zde naleznete aktuálně vypsané termíny kurzů, jejich místa konání a možnost registrace."
                />
            </Helmet>
            <div className="terminy__header">
                <h2>Termíny kurzů</h2>
                <p>
                    Zde naleznete aktuálně vypsané kurzy, jejich místa konání a
                    možnost registrace. Dle ikonky poznáte, zda je kurz určen
                    pro osoby využívající ruční řízení nebo pro osoby se
                    sluchovým postižením.
                </p>
            </div>
            <div className="terminy__box">
                {!loading ? (
                    <>
                        <div className="terminy__box__filter">
                        {!isMobile ? ( <>
                            <div className="filtr" style={{justifyContent:'flex-end'}}>
                            {places?.options?.length>0&&places?.options?.map((p, i) => (
                                    <>
                                        {i == 0 && (
                                            <a
                                                className={
                                                    active === 0 ? "active" : ""
                                                }
                                                onClick={() => {
                                                    setFilter(null);
                                                    setActive(0);
                                                }}
                                            >
                                                Vše
                                            </a>
                                        )}
                                        <a
                                            key={i}
                                            className={
                                                active === i + 1 ? "active" : ""
                                            }
                                            onClick={() => {
                                                setFilter({
                                                    name: "location",
                                                    value: p,
                                                });
                                                setActive(i + 1);
                                            }}
                                        >
                                            {p}
                                        </a>
                                    </>
                                ))
                            }
                            </div>
                            <span className="divider"></span>
                            <div className="filtr" style={{justifyContent:'flex-start'}}><a className={type==''&&'active'} onClick={()=>setType('')}>Vše</a> <a className={type==='mobility'&&'active'} onClick={()=>setType('mobility')}>hendikepovaní</a> <a className={type==='hearing'&&'active'} onClick={()=>setType('hearing')}>neslyšící</a>
                            </div></>) : <>
                                <button className="blue sliders"><Sliders onClick={()=>setModal(true)} /></button>
                                <div style={{left:modal?'0':'100%'}} className="overlay">
                                    <X className="close" onClick={()=>{setModal(false)}} />
                                    <h3>Filtrovat</h3>
                                    <Dropdown defaultValue={places[active]} options={places} onChange={(opt)=>{setFilter(opt.value!==0?{name:'location', value:opt.label}:null);setActive(opt.value);}} name='location' label='Město'  />
                                    <Dropdown defaultValue={[{value:'', label:'Vše'}, {value:'mobility', label:'Hendikepovaní'}, {value:'hearing', label:'Neslyšící'}].find(v=>v.value==type)} 
                                    options={[{value:'', label:'Vše'}, {value:'mobility', label:'Hendikepovaní'}, {value:'hearing', label:'Neslyšící'}]}
                                    onChange={(opt)=>setType(opt.value)} name='type' label="Typ"
                                    />
                                    <button className="blue" onClick={filterEvents}>Vyhledat</button>
                                </div>
                            </>}
                        </div>
                        <div
                            className="terminy__box__content"
                            style={
                                loading ? { gridTemplateColumns: "1fr" } : {}
                            }
                        >
                            {!loading ? (
                                eventsData?.count>0 ? eventsData?.events?.map((event, i) => (
                                    <React.Fragment key={i}>
                                        <Event event={event} />
                                    </React.Fragment>
                                )): <strong>Pro tento výběr neexistují žádné události.</strong>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
        </Layout>
    );
};

export default Terminy;
