import React, { createContext, useContext, useState } from "react";
import "./Toast.scss"
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import { CheckCircle, Info, WarningCircle, XCircle } from "@phosphor-icons/react";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = ({type, message, autoclose=15000}) => {
      let toastId = Date.now();
      let timeout = autoclose ? setTimeout(() => deleteToast(toastId), autoclose) : null;
      let nodeRef = React.createRef();
      setToasts(prevToasts => [...prevToasts, { id: toastId, message, type, timeout, nodeRef }]);
    }

    const deleteToast = (toastId) => {
      setToasts(prevToasts => prevToasts.filter(t => {
          if (t.id === toastId && t.timeout) clearTimeout(t.timeout);
          return t.id !== toastId;
      }));
    }

    return <ToastContext.Provider value={{addToast, deleteToast}}>
      {children}
      <TransitionGroup component='div' className='supertoasts'>
        {toasts.map(t=>
          <CSSTransition key={t.id} timeout={300} nodeRef={t.nodeRef} classNames='toast' ><Toast ref={t.nodeRef}  {...t} /></CSSTransition>
        )}
      </TransitionGroup>
    </ToastContext.Provider>;
};

export const useToast = () => {
  return useContext(ToastContext);
};

const Toast = ({id, type='info', message, nodeRef}) => {
  const {deleteToast} = useToast();
  const types = {
    info: {
      name: 'Info',
      icon: <Info />
    },
    success: {
      name: 'Success',
      icon: <CheckCircle />
    },
    error: {
      name: 'Error',
      icon: <XCircle />
    },
    warning: {
      name: 'Warning',
      icon: <WarningCircle />
    }
  }
  return (
      <div ref={nodeRef} className={'supertoast '+type}>
        {React.cloneElement(types[type].icon, {style: {width:'40px', height:'40px'}})}
        <div className="supertoast__box">
          {/* <strong>{types[type].name}</strong> */}
          <p>{message}</p>
        </div>
        <button onClick={()=>deleteToast(id)}>&times;</button>
      </div>
  )
}