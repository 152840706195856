import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay,Navigation } from "swiper/modules"
import 'swiper/css/bundle';

const Galerie = () => {
    let slidesNum = window.innerWidth>2000?4:window.innerWidth>1200?3:window.innerWidth>800?2:1;
    return <section className="gallery">
        <Swiper
            modules={[Autoplay, Navigation]}
            autoplay={{delay:5000}}
            loop={true}
            navigation={true}
            slidesPerView={slidesNum}
        >
            <SwiperSlide><img src={"./galerie/A7_04457.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04459.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04460.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04462.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04465.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04479.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04512.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04543.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04568.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04608.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04614.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04618.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04624.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/A7_04634.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/IMG_8860.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/IMG_8869.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/IMG_8882.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/IMG_8883.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/IMG_8889.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/IMG_8893.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/IMG_8919.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/IMG_8948.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/IMG_8957-Enhanced-NR-2.jpg"} /></SwiperSlide>  
            <SwiperSlide><img src={"./galerie/Kurzy_Sosnova-Cesta_za_snem-rijen_17.jpg"} /></SwiperSlide>  
        </Swiper>
    </section>
}

export default Galerie