import IG from "../../assets/loga/IG.svg";
import FB from "../../assets/loga/FB.svg";

import SNNSR from "../../assets/loga/PV0-Hendikepovani-web-loga-512x512px-svaz-neslysicich.svg";
import PV0KOOP from "../../assets/loga/PV0-Hendikepovani-web-loga-512x512px-PV0xKOOP-white.svg";
import WSTWV from "../../assets/loga/PV0-Hendikepovani-web-loga-512x512px_WSTWV.svg";
import SKODA from "../../assets/loga/PV0-Hendikepovani-web-loga-512x512px-SKODA.svg";
import CZEPA from "../../assets/loga/PV0-Hendikepovani-web-loga-512x512px-CZEPA.png";
import MD from "../../assets/loga/PV0-Hendikepovani-web-loga-512x512px-MDCR.svg";

const Contact = () => {
    return (
        <>
            <section className="contact" id="contact">
                <div className="contact__box">
                    <div className="contact__box__left">
                        <div className="perex">
                            <p>Kontakt</p>
                            <h2>Zůstaňte s námi v kontaktu</h2>
                        </div>
                        <p>
                            Pro více informací a zajímavostí o dopravě a dopravní
                            bezpečnosti nás sledujte na našich sociálních sítích.
                        </p>
                        <div className="socials">
                            <a href="https://www.facebook.com/vize0.cz/" target="_blank">
                                <img src={FB} alt="Facebook" />
                                <span>@vize0.cz</span>
                            </a>
                            <a href="https://www.instagram.com/vize0.cz/" target="_blank">
                                <img src={IG} alt="Instagram" />
                                <span>@vize0.cz</span>
                            </a>
                        </div>
                    </div>
                    <div className="contact__box__right"></div>
                </div>
            </section>
            <section className="partners">
                <a href="https://www.wst-versicherungsverein.at/ " target="_blank"><img src={WSTWV} alt="Logo - WIENER STÄDTISCHE VERSICHERUNGSVEREIN"/></a> 
                <a href="https://www.platformavize0.cz/" target="_blank"><img src={PV0KOOP} alt="Logo - Platforma vize nula ve spolupráci s Nadací Kooperativy" /></a>
                <a href="https://www.skoda-auto.cz/" target="_blank"><img src={SKODA} alt="Logo - Škoda auto"/></a>
                <a href="https://czepa.cz/" target="_blank"><img src={CZEPA} alt="Logo - CZEPA" /></a>
                <a href="https://www.snncr.cz/" target="_blank"><img src={SNNSR} alt="Logo - Svaz neslyšících a nedoslýchavých osob v ČR"/></a> 
                <a href="https://www.mdcr.cz/" target="_blank"><img src={MD} alt="Logo - Ministerstvo dopravy"/></a>
            </section>
        </>
    );
};

export default Contact;
