import Header from "./components/Header/Header"
import Navigation from "./components/Header/Navigation"
import logoPV0Kooop from "./assets/loga/PV0 x KOOP.svg";
import kurzyPV0 from "./assets/loga/PV0_kurzy_logo.svg";
import { showCookieConsent } from "./components/UI/Cookies";
import Overlay from "./components/Header/Overlay"
import { Link } from "react-router-dom";
import HashLink from "./components/UI/HashLink";
import signicon from "./assets/svg/sign_icon.svg"
import { useState } from "react";
import Modal from "./components/UI/Modal";
import ReactPlayer from "react-player";

const Layout = ({ children, nested = 0, ...rest }) => {
    const showConsent = () => {
        showCookieConsent();
    }
    const [ZJ, setZJ] = useState(false);
    return (
        <>
            <Header stopScroll fixed breakpoint={1000}>
                <div className="container">
                    <Link to='/'><img src={logoPV0Kooop} className='pv0' alt="PV0 + KOOP logo" /> </Link>
                    <Link to='/'><img src={kurzyPV0} alt="Kurzy PV0 logo" /></Link>

                    <Navigation moveRight hideOnMobile={1000}>
                        <HashLink href='#projekt' nested={nested}>Projekt</HashLink>
                        <HashLink href="#kurzy" nested={nested}>Kurzy</HashLink>
                        <HashLink href="#petice" nested={nested}>Petice</HashLink>
                        <HashLink href="#contact" nested={nested}>Kontakt</HashLink>
                        <HashLink
                            id='terminyBtn'
                            className="button transWhite"
                            href='#events'
                            nested={nested}
                        >
                            TERMÍNY KURZŮ
                        </HashLink>
                        <a title="Videa s překladem do ZJ" onClick={()=>setZJ(true)} style={{marginLeft:"-10px"}} ><img className="ikonka_zj" src={signicon} alt="Ikonka ZJ" /> </a>
                    </Navigation>
                </div>
                <Overlay width='50vw' justify='space-evenly'>
                    <HashLink href='#projekt' nested={nested}>Projekt</HashLink>
                    <HashLink href="#kurzy" nested={nested}>Kurzy</HashLink>
                    <HashLink href="#petice" nested={nested}>Petice</HashLink>
                    <HashLink href="#contact" nested={nested}>Kontakt</HashLink>
                    <HashLink
                        id='terminyBtn'
                        className="button blue"
                        href='#events'
                        nested={nested}
                    >
                        TERMÍNY KURZŮ
                    </HashLink>
                    <a title="Videa s překladem do ZJ" onClick={()=>setZJ(true)} ><img className="ikonka_zj" src={signicon} alt="Ikonka ZJ" /> </a>
                </Overlay>
            </Header>
            <main {...rest}>
                {children}
            </main>
            <footer>
                <div className="footer">
                    <div className="footer__left">
                        <div className="container">
                            <img src={logoPV0Kooop} alt="PV0 + KOOP logo" />
                            <div className="vl"></div>

                            <img src={kurzyPV0} alt="Kurzy PV0 logo" />
                        </div>
                        <p>Platforma VIZE 0, z. ú. <br />Pobřežní 665/21, <br />Karlín 186 00 Praha 8</p>
                    </div>
                    <div className='footer__right'>
                        <div>
                            <HashLink nested={nested} href="#project">Projekt</HashLink>
                            <HashLink nested={nested} href="#events">Akce</HashLink>
                            <HashLink nested={nested} href="#kurzy">Kurz</HashLink>
                            <HashLink nested={nested} href="#contact">Kontakt</HashLink>
                        </div>
                        <div>
                            <a href="/documents/PV0_GDPR.pdf" target='_blank'>GDPR</a>
                            <span onClick={showConsent}>Cookies</span>
                        </div>
                    </div>
                </div>
                <div className="copyright">Copyright © {new Date().getFullYear()} Platforma VIZE 0</div>
            </footer>
            <Modal state={[ZJ, setZJ]}>
                <ReactPlayer url="https://www.youtube.com/embed/-vx05eINZlc?si=rrkNXR7f0varGWQq" controls width="80vw" height='40vw' />
            </Modal>
        </>
    )
}

export default Layout
