import { useContext, useState } from "react";
import { Store } from "../App";
import hybnost from "../assets/svg/hybnost.svg";
import sluch from "../assets/svg/sluch.svg";
import Loading from "./UI/Loading";

const Event = ({ event }) => {
    const { setRegisterModal, loadingCard, setLoadingCard } = useContext(Store);

    let mobility = event.mobility =="true"||event.mobility =="1";
    let hearing = event.hearing =="true"||event.hearing =="1";

    //Geting date from unformatted date
    const getDateFormat = (input) => { 
        var newDate_from = new Date(input.date);

        const f_yyyy = newDate_from.getFullYear();
        let f_mm = ("0" + (newDate_from.getMonth() + 1)).slice(-2);
        let f_dd = ("0" + newDate_from.getDate()).slice(-2);

        if (input.date_to) {
            var newDate_to = new Date(input.date_to);
            const t_yyyy = newDate_to.getFullYear();
            let t_mm = ("0" + (newDate_to.getMonth() + 1)).slice(-2);
            let t_dd = ("0" + newDate_to.getDate()).slice(-2);

            let m = f_mm < t_mm ? ". " + t_mm : "";
            return f_dd + m + " - " + t_dd + ". " + t_mm + ". " + t_yyyy;
        } else {
            return f_dd + ". " + f_mm + ". " + f_yyyy;
        }
    };

    return (
        <div className="event">
            <div className="event__header">
                <span>{getDateFormat(event)}</span>
                <span>
                    {mobility  && (
                        <img
                            src={hybnost}
                            alt="pro osoby s omezenou hybnosti"
                        />
                    )}
                    {hearing  && (
                        <img src={sluch} alt="pro sluchově postižené" />
                    )}
                </span>
            </div>
            <div className="event__body">
                <p>Pro osoby <b>se {mobility&&'zdravotním'}{mobility&&hearing&&' / '}{hearing&&'sluchovým'} postižením</b></p>
                <h2>{event.name}</h2>
                <span>{event.place}</span>
            </div>
            <div className="event__footer"> 
                <span>{event.pricing}</span>
                {loadingCard===event.id?<button className="blue loading"><Loading /></button>:<button
                    type="button"
                    disabled={event?.capacity>0?false:true}
                    className={event?.capacity>0?"blue":"disabled"}
                    onClick={() => {setRegisterModal({data:event, name:"event_register",event_id:event.id});setLoadingCard(event.id)}}
                >
                    {event?.capacity>0?"Registrovat":"Plná kapacita"}
                </button>}
            </div>
        </div>
    );
};

export default Event;
