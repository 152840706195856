import { useContext } from "react";
import { Store } from "../../App";

const Petice = () => {
    const { setRegisterModal } = useContext(Store);
    return (
        <>
            <section className="petice" id="petice">
                <div className="petice__box">
                    <div className="perex">
                        <p>Petice</p>
                        <h2>ZATOČTE S AGRESIVITOU NA SILNICI!</h2>
                    </div>
                    <p>
                        Přejete si klidné silnice bez agresivních řidičů? Připravujeme petici podporující zavedení změn v zákoně o možnosti sankcionování agresivity za volantem. Pokud chcete být informováni o jejím spuštění, zanechte nám svůj e-mail.
                    </p>
                    <button type="button" className="blue" onClick={() => setRegisterModal({name:"petice"})}>
                        Chci být informován
                    </button>
                </div>


            </section>
        </>
    );
};

export default Petice;
