import { useForm, FormProvider } from 'react-hook-form';
import "./Form.scss";
import { useEffect } from 'react';

const Form = ({children, className='', onSubmit, onWatch=()=>{}}) => {
    const methods = useForm({mode: 'onChange'});
    const {handleSubmit, watch, reset} = methods;
    const watchAll = watch();

    useEffect(()=>{
        const subscription = watch(onWatch)
        return () => subscription.unsubscribe()
    }, [watchAll])

    const onSubmitReset = (data) => {
        onSubmit(data);
        setTimeout(reset, 500);
    }
    

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmitReset)} className={'superform '+ className}>
                {children}
            </form>
        </FormProvider>
    )
}

export default Form;