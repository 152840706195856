import Intro from "./components/Sections/Intro";
import Kurzy from "./components/Sections/Kurzy";
import Contact from "./components/Sections/Contact";
import Petice from "./components/Sections/Petice";
import Events from "./components/Sections/Events";
import Project from "./components/Sections/Project";
import { Helmet } from "react-helmet";
import Layout from "./Layout";
import Galerie from "./components/Sections/Galerie";

const Home = () => {
    return (
        <Layout>
            <Helmet> 
                <title>Zlepšete své schopnosti za volantem | Kurzy pro řidiče s hendikepem</title>
                <meta name="description" content="Kurzy nabízí lidem s hendikepem zažití nepředvídatelných situací silničního provozu v bezpečných podmínkách polygonu." />
                <meta property="og:title" content="Zlepšete své schopnosti za volantem" />
                <meta property="og:description" content="Kurzy nabízí lidem s hendikepem zažití nepředvídatelných situací silničního provozu v bezpečných podmínkách polygonu." />
                <meta property="og:site_name" content="Kurzy pro řidiče s hendikepem" />
                <meta name="twitter:title" content="Zlepšete své schopnosti za volantem" />
                <meta name="twitter:description" content="Kurzy nabízí lidem s hendikepem zažití nepředvídatelných situací silničního provozu v bezpečných podmínkách polygonu." />
            </Helmet>
            <Intro />
            <Project />
            <Kurzy />
            <Events />
            <Galerie />
            <Petice />
            <Contact />
        </Layout>
    )
}

export default Home;
