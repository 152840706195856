
import hybnost from "../../assets/svg/hybnost.svg";
import sluch from "../../assets/svg/sluch.svg";
import ReactPlayer from 'react-player'

const Project = () => {
    return (
        <>
            <section className="project" id="projekt">
                <div className="project__box">
                    <div className="project__box__left">
                        <div className="perex">
                            <p>o Projektu</p>
                            <h2>PRO KOHO JE KURZ URČEN?</h2>
                        <strong>
                            Kurzy bezpečné jízdy umožňují osobám s omezením hybnosti, individuální úpravou automobilu nebo sluchovým postižením zlepšit své řidičské dovednosti a sebedůvěru za volantem.
                        </strong>
                        </div>
                        <div className="icons"> <img src={hybnost} alt="pro osoby s omezenou hybnosti" /> <img src={sluch} alt="pro sluchově postižené" /></div>
                        <p>
                        Kurzy nabízí jedinečnou možnost vyzkoušení nepředvídatelných situací silničního provozu v bezpečných podmínkách. Teoreticky i prakticky vás tématy kurzu provede zkušený lektor, který vám pomůže vaše dosavadní znalosti rozšířit. Kurzy jsou přizpůsobeny oběma skupinám lidí s hendikepem. Osoby s ručním ovládáním musí mít své upravené vozidlo a pro osoby se sluchovým postižením v rámci výuky poskytujeme tlumočníka do znakového jazyka.
                        </p>
                    </div>
                    <div className="project__box__right">
                        <ReactPlayer url='https://youtu.be/vlJLxtkiOr0' />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Project;
