import { useState } from "react";
import tri from "../../assets/img/triangle.png"
import tribg from "../../assets/img/trianglebg.png"
import signicon from "../../assets/svg/sign_icon_blue.svg"
import ReactPlayer from "react-player";
import Modal from "../UI/Modal";

const Kurzy = () => {

    const [ZJ, setZJ] = useState(false);

    return (
        <section className="kurzy" id='kurzy'>
            <div className="kurzy__box">
                <div className="perex">
                    <p>kurzy</p>
                    <h2>KURZY BEZPEČNÉ JÍZDY</h2>
                </div>
                <p>Propojení teoretické a praktické jízdy vytváří komplexní kurz bezpečné jízdy. <br />Zažijete nebezpečné situace silničního provozu v bezpečných podmínkách polygonu  <br /> a poznáte své vozidlo jako nikdy předtím.</p>
                <div className="kurzy__container">

                    <div className="kurzy__container__left">
                        <img src={tribg} className="bg" />
                        <img src={tri} />
                </div>
                    <div className="kurzy__container__right">
                        <h2>Obsah kurzu</h2>
                        <span >Kurzy se budou skládat z teoretické a praktické části.</span>
                        <p>V teoretické části se dozvíte informace o základních fyzikálních vlastnostech jízdy s automobilem, krizových situacích,
                            možnosti čerpání příspěvků a úprav automobilu. <br /><br />


                            Během praktické části si vyzkoušíte jízdy na jednotlivých cvičných plochách za běžných povětrnostních podmínek,
                            ale i za snížené adheze na speciálních kluzných plochách. Dále krizové brzdění, vyhýbací manévry, slalomy, parkování apod.</p>

                        <div className="kurzy__container__right__btns">
                            <a className="button transBlue" target="_blank" href="/documents/PODMINKY-UCASTI-NA-KURZU_PV0.pdf" >podmínky účasti</a>
                            <a title="Videa s překladem do ZJ" onClick={()=>setZJ(true)} ><img className="ikonka_zj" src={signicon} alt="Ikonka ZJ" /> </a>
                        </div>
                    </div>

                </div>
                
            </div>
            <Modal state={[ZJ, setZJ]}>
                <ReactPlayer url="https://www.youtube.com/embed/fQhUip_pTx0?si=zBGhS1ZXuW-RDEbF" controls width="80vw" height='40vw' />
            </Modal>
        </section>
    )
}

export default Kurzy