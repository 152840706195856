import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Event from "../Event";
import axios from "axios";
import { APIURL } from "../..";
import Loading from "../UI/Loading";

const Events = () => {
    const [eventsData, setEventsData] = useState({});
    const [loading, setLoading] = useState(true);

    const getCurrentDate = () => {
        var now = new Date();
        var currentMonth = now.getMonth() + 1;
        if (currentMonth < 10) {
            currentMonth = "0" + currentMonth;
        }
        return now.getFullYear() + "-" + currentMonth + "-" + now.getDate();
    };

    const getEvents = async () => {
        setLoading(true);
        await axios
            .post(APIURL + "/events", {
                type: 'events',
                orderAsc: "ASC",
                limit: 3,
                page: 1,
                filter: [
                    { name: "date", action: ">", value: getCurrentDate() },
                ],
            })
            .then((response) => {
                setEventsData(response.data.body);
                setLoading(false);
            });
    };

    useEffect(() => {
        getEvents();
    }, []);
    return (
        <section className="events" id="events">
            <div className="events__box">
                <div className="events__container">
                    {!loading ? (
                        <>
                            {eventsData?.events?.map((event, i) => (
                                <React.Fragment key={i}>
                                    <Event event={event} />
                                </React.Fragment>
                            ))}
                        </>
                    ) : ( 
                        <Loading /> 
                    )}
                </div>
                <Link to="/terminy" className="button transBlue">
                    zobrazit termíny
                </Link>
            </div>
        </section>
    );
};

export default Events;
