import { ReactLenis } from "@studio-freight/react-lenis";
import { createContext, useEffect, useState } from "react";
import FormModal from "./components/FormModal";
import { initCookieConsent } from "./components/UI/Cookies";
import { ToastProvider } from "./components/UI/useToast";

import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./Home";
import Terminy from "./Terminy";
import HashScroll from "./components/UI/HashScroll";

export const Store = createContext();
const App = () => {
    const [registerModal, setRegisterModal] = useState(null);
    const [loadingCard, setLoadingCard] = useState(null);
    useEffect(() => {
        initCookieConsent();
    });

    return (
        <ReactLenis root>
            <ToastProvider>
                <Store.Provider value={{ setRegisterModal, loadingCard, setLoadingCard }}>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path="/terminy" element={<Terminy />} />
                        <Route path="*" element={<Navigate to='/' />} />
                    </Routes>
                    <FormModal
                        state={[registerModal, setRegisterModal]}
                    />
                </Store.Provider>
                <HashScroll />
            </ToastProvider>
        </ReactLenis>
    );
};

export default App;
