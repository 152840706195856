import { useLenis } from "@studio-freight/react-lenis";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

const Intro = () => {
    const lenis = useLenis();
    const scroll = (e, hash) => {
        e.preventDefault();
        lenis.scrollTo(document.getElementById(hash));
    };
    return (
        <>
            <section className="intro" id="intro">
                <div className="intro__title">
                    <h1>
                        <span>Zlepšete své </span> <br />
                        <span>schopnosti</span> <br />
                        <span>za volantem</span>
                    </h1>
                    <button type="button" onClick={(e) => scroll(e, "events")}>
                        TERMÍNY KURZŮ
                    </button>
                </div>
                <Swiper
                    modules={[Autoplay]}
                    autoplay={{ delay: 3000 }} 
                >
                    <SwiperSlide className="image i1"></SwiperSlide>
                    <SwiperSlide className="image i2"></SwiperSlide>
                </Swiper>
            </section>
        </>
    );
};

export default Intro;
